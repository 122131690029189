<template>
  <div>
    <el-affix>
      <nav-bar></nav-bar>
    </el-affix>
    <tool-box></tool-box>

    <div class="container">
      <!-- 教师信息 -->
      <div class="teacher-info">
        <div class="teacher-info-img">
          <img :src="teacherDetail.thumb" />
        </div>
        <div class="teacher-info-introduce">
          <div class="introduce-header">
            <div class="name">{{ teacherDetail.name }}</div>
            <div class="follow">+关注</div>
          </div>
          <div class="introduce-special">{{ teacherDetail.spec_id.title }}</div>
          <div class="introduce-content" v-html="stripHTML(teacherDetail.content)"></div>
        </div>
      </div>

      <!-- tabs -->
      <div class="tabs">
        <div :class="'tabs-item' + (currentTab == 1 ? ' active' : '')"
          @click="doChangeTab(1)"><h1>套餐</h1></div>
        <!-- <div :class="'tabs-item' + (currentTab == 2 ? ' active' : '')"
          @click="doChangeTab(2)"><h1>试听</h1></div> -->
      </div>

      <!-- 列表 -->
      <div class="grade-list" v-if="teacherDetail.combo && teacherDetail.combo.length > 0">
        <div class="grade-list-item" v-for="item in teacherDetail.combo" :key="item.id"
          @click="goDetail(item)">
          <div class="item-img"><img :src="item.thumb" alt=""></div>
          <div class="item-title" :title="item.title">{{ item.title }}</div>
          <div class="item-price">￥{{ item.sale_price }}</div>
        </div>
      </div>
      <el-empty v-else description="暂无数据"></el-empty>
    </div>

    <web-footer></web-footer>
  </div>
</template>

<script>
import NavBar from "@/components/page/NavBar"
import ToolBox from "@/components/toolbox"
import WebFooter from "@/components/page/WebFooter"
import { getCombo } from "@/api/teacher"
import { stripHTML } from '@/utils/index'

export default {
  components: {
    NavBar,
    ToolBox,
    WebFooter,
  },
  data() {
    return {
      // 教师信息
      teacherDetail: {
        name: '',
        spec_id: {title: ''},
        content: '',
        grade: []
      },
      // 当前选中的tab
      currentTab: 1,
      // 套餐、试听列表
      dataList: [],

    }
  },
  created() {
    getCombo({teacher_id: this.$route.params.id}).then(res => {
      if (res.code == 1) {
        this.teacherDetail = res.data
      }
    })
  },
  methods: {
    stripHTML,
    // 切换tab
    doChangeTab(tab) {
      this.currentTab = tab
    },
    // 跳转到详情
    goDetail(combo) {
      this.$router.push('/coursedetail/' + combo.spec_id + '/' + combo.comboclass_id + '/' + combo.id)
    },

  },
  
};
</script>

<style scoped lang="scss">
.container {
  margin: 20px auto;
}

// 教师信息 begin
.teacher-info {
  background: $background-color;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;

  .teacher-info-img {
    max-width: 280px;
    min-width: 220px;

    img {
      width: 100%;
    }
  }
  .teacher-info-introduce {
    padding: 20px;

    .introduce-header {
      display: flex;

      .name {
        font-size: 24px;
        font-weight: 700;
      }
      .follow {
        color: $danger-color;
        width: 85px;
        height: 30px;
        line-height: 30px;
        font-size: 20px;
        text-align: center;
        border: 2px solid $danger-color;
        border-radius: 8px;
        margin-left: 30px;
        cursor: pointer;
      }
    }
    .introduce-special {
      width: 140px;
      height: 40px;
      line-height: 40px;
      font-size: 18px;
      text-align: center;
      background-color: #f5f4f4;
      border-radius: 10px;
      margin: 20px 0;
    }
    .introduce-content {
      color: #666;
      line-height: 35px;
      font-size: 18px;
    }
  }
}
// 教师信息 end

// tabs begin
.tabs {
  height: 60px;
  background-color: $background-color;
  box-shadow: 0 1px 2px rgb(0 0 0 / 2%);
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;

  .tabs-item {
    width: 200px;
    position: relative;
    cursor: pointer;
  }
  .tabs-item > h1 {
    height: 26px;
    font-size: 18px;
    text-align: center;
    border-right: 2px solid #666;
    margin-top: 18px;
  }
  .tabs-item:last-child h1 {
    border: none;
  }
  .tabs-item:hover h1,
  .tabs-item.active h1 {
    color: $theme-color;
  }
  .tabs-item:hover::before,
  .tabs-item.active::before {
    content: "";
    width: 48px;
    height: 2px;
    background-color: $theme-color;
    margin-left: -24px;
    position: absolute;
    left: 50%;
    bottom: -1px;
  }
}
// tabs end

// 班级列表 begin
.grade-list {
  display: flex;
  flex-wrap: wrap;
  .grade-list-item {
    width: 290px;
    min-height: 220px;
    background-color: $background-color;
    margin: 10px 5px;
    cursor: pointer;
    .item-img {
      width: 100%;
      height: 160px;
    }
    .item-title {
      font-size: 16px;
      padding: 5px 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .item-price {
      color: $price-color;
      font-size: 24px;
      padding: 10px;
    }
  }
  .grade-list-item:hover {
    color: $danger-color;
    box-shadow: 0px 0px 3px 3px #ccc;
  }
}
// 班级列表 end
</style>
